<template>
  <v-dialog v-model="show" persistent scrollable>
    <v-card outlined>
      <!-- <v-system-bar color="grey lighten-2" height="32">
        <v-spacer></v-spacer>
        <v-btn icon small @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar> -->
      <v-card-title>
        {{ $t("Schemă colecție") }}
        <v-spacer />

        <!-- <v-icon @click="show = false">mdi-close</v-icon> -->
        <v-icon color="red" class="mt-n2 mr-n2" large @click="show = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form ref="dataForm">
          <v-row dense>
            <v-col cols="6">
              <v-text-field v-model="title" dense autofocus :label="$t('Nume')" :rules="inputRequired" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="table" dense :label="$t('Colecție')" :rules="inputRequired" />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="appTypes"
                dense
                :label="$t('Tip aplicație')"
                item-text="name"
                item-value="value"
                :rules="inputRequired"
                :items="appTypesValues"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="companies"
                dense
                :label="$t('Companie')"
                item-text="name"
                item-value="_id"
                :disabled="appType != 'system'"
                :items="companiesValues"
              />
            </v-col>
            <v-col class="dense" cols="12">
              <v-tabs v-model="selectedTab" @change="getJson">
                <v-tab>{{ $t("Schiță") }}</v-tab>
                <v-tab>{{ $t("JSON") }}</v-tab>
                <v-tab v-if="record._id">{{ $t("Index") }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="selectedTab">
                <v-tab-item>
                  <v-row>
                    <v-col class="py-2 dense" cols="12">
                      <draggable
                        class="list-group"
                        :list="mainList"
                        :clone="cloneComponent"
                        :group="{ name: 'g1', pull: 'clone', put: false }"
                      >
                        <v-chip
                          v-for="el in mainList"
                          :key="el.id"
                          style="cursor:move"
                          label
                          class="list-group-item ml-2 mt-2"
                          @mouseenter="el.show = true"
                          @mouseleave="el.show = false"
                        >
                          <v-icon>{{ el.icon }}</v-icon>
                          <span v-if="el.show">{{ el.type }}</span>
                        </v-chip>
                      </draggable>
                    </v-col>
                    <v-col class="page-card-design my-2" cols="9">
                      <scream-drag
                        :schema="list"
                        :selected-id="selectedId"
                        style="height:100%"
                        @selectedIdChange="
                          selectedId = $event.id
                          selectedItem = $event
                        "
                      />
                    </v-col>
                    <v-col class="page-card-design py-2" cols="3">
                      <v-sheet
                        v-if="selectedItem.type"
                        class="pt-3"
                        style="height:100%;overflow-y: auto;overflow-x: hidden;"
                      >
                        <!-- <v-tabs class="tab-details">
                          <v-tab>{{ $t("Details") }}</v-tab>
                          <v-tab>{{ $t("Meta") }}</v-tab>
                          <v-tab>{{ $t("Relation") }}</v-tab>
                        </v-tabs> -->
                        <v-text-field v-model="selectedItem.key" dense :label="$t('Câmp')" />

                        <v-subheader>{{ $t("Metadate") }}</v-subheader>
                        <v-text-field v-model="selectedItem.meta.label" dense :label="$t('Etichetă')" />
                        <v-text-field v-model="selectedItem.meta.labelEn" dense :label="$t('Eticheta engleză')" />
                        <v-text-field v-model="selectedItem.meta.mandatory" dense :label="$t('Obligatoriu')" />
                        <v-text-field v-model="selectedItem.meta.repeatable" dense :label="$t('Repetabil')" />
                        <v-text-field v-model="selectedItem.meta.length" dense :label="$t('Lungime')" />
                        <v-text-field v-model="selectedItem.meta.component" dense :label="$t('Componentă')" />
                        <v-text-field v-model="selectedItem.meta.editable" dense :label="$t('Editabil')" />
                        <v-checkbox v-model="selectedItem.meta.showInTable" dense :label="$t('Afișare în tabel')" />
                        <v-text-field v-model="selectedItem.meta.regex_info" dense :label="$t('Informație Regex')" />
                        <v-text-field v-model="selectedItem.meta.to_prefix" dense :label="$t('Prefix legătură')" />
                        <v-text-field v-model="selectedItem.meta.to_field" dense :label="$t('Câmp legătură')" />
                        <v-text-field v-model="selectedItem.meta.to_subfield" dense :label="$t('Subcâmp legătură')" />
                        <v-text-field v-model="selectedItem.meta.to_category" dense :label="$t('Categorie legătură')" />

                        <v-subheader>{{ $t("Detalii") }}</v-subheader>
                        <v-checkbox
                          v-if="selectedItem.required != null"
                          v-model="selectedItem.required"
                          dense
                          class="mt-0"
                          :label="$t('Obligatoriu')"
                        />
                        <v-checkbox
                          v-if="selectedItem.readonly != null"
                          v-model="selectedItem.readonly"
                          dense
                          class="mt-0"
                          :label="$t('Needitabil')"
                        />
                        <v-text-field
                          v-if="selectedItem.minlength !== undefined"
                          v-model.number="selectedItem.minlength"
                          dense
                          type="number"
                          min="0"
                          :label="$t('Lungime minimă')"
                        />
                        <v-text-field
                          v-if="selectedItem.maxlength !== undefined"
                          v-model.number="selectedItem.maxlength"
                          dense
                          type="number"
                          min="0"
                          :label="$t('Lungime maximă')"
                        />
                        <v-text-field
                          v-if="selectedItem.min !== undefined"
                          v-model.number="selectedItem.min"
                          dense
                          type="number"
                          min="0"
                          :label="$t('Minim')"
                        />
                        <v-text-field
                          v-if="selectedItem.max !== undefined"
                          v-model.number="selectedItem.max"
                          dense
                          type="number"
                          min="0"
                          :label="$t('Maxim')"
                        />
                        <v-checkbox
                          v-if="selectedItem.empty != null"
                          v-model="selectedItem.empty"
                          dense
                          class="mt-0"
                          :label="$t('Gol')"
                        />
                        <v-checkbox
                          v-if="selectedItem.unique != null"
                          v-model="selectedItem.unique"
                          dense
                          class="mt-0"
                          :label="$t('Unic')"
                        />
                        <v-checkbox
                          v-if="selectedItem.nullable != null"
                          v-model="selectedItem.nullable"
                          dense
                          class="mt-0"
                          :label="$t('Anulabil')"
                        />
                        <v-text-field
                          v-if="selectedItem.default != null && selectedItem.type != 'boolean'"
                          v-model="selectedItem.default"
                          dense
                          :label="$t('Predefinit')"
                        />
                        <v-select
                          v-if="selectedItem.default != null && selectedItem.type == 'boolean'"
                          v-model="selectedItem.default"
                          :items="boolValue"
                          dense
                          :label="$t('Predefinit')"
                        />
                        <v-text-field
                          v-if="selectedItem.regex != null"
                          v-model="selectedItem.regex"
                          dense
                          :label="$t('Regex')"
                        />
                        <v-text-field
                          v-if="selectedItem.data_relation != undefined"
                          v-model="selectedItem.data_relation.resource"
                          dense
                          :label="$t('Legătură')"
                        />
                        <v-text-field
                          v-if="selectedItem.data_relation != undefined"
                          v-model="selectedItem.data_relation.field"
                          dense
                          :label="$t('Câmp legătură')"
                        />
                        <v-text-field
                          v-if="selectedItem.data_relation != undefined"
                          v-model="selectedItem.meta.field"
                          dense
                          :label="$t('Etichetă câmp')"
                        />
                        <v-checkbox
                          v-if="selectedItem.data_relation != null"
                          v-model="selectedItem.data_relation.embeddable"
                          dense
                          class="mt-0"
                          :label="$t('Încorporabil')"
                        />
                        <v-row v-for="(itm, ind) in selectedItem.allowed" :key="ind">
                          <v-col cols="12">
                            <v-text-field
                              prepend-icon="mdi-plus"
                              append-icon="mdi-minus"
                              :value="itm"
                              :label="$t('Permis')"
                              class="pt-0"
                              dense
                              @click:append="
                                selectedItem.allowed.length > 1
                                  ? selectedItem.allowed.splice(ind, 1)
                                  : selectedItem.allowed.splice(ind, 1, '')
                              "
                              @click:prepend="selectedItem.allowed.splice(ind + 1, 0, '')"
                              @input="selectedItem.allowed[ind] = $event"
                            />
                          </v-col>
                        </v-row>
                        <v-spacer />
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="page-card">
                  <pre>{{ listJson }}</pre>
                </v-tab-item>
                <v-tab-item>
                  <!-- <v-autocomplete multiple chips small-chips deletable-chips :items="listFields" /> -->
                  <v-simple-table dense class="page-card-design">
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left">Name</th>
                          <th class="text-left"></th>
                          <th class="text-left">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in listFields" :key="item.name">
                          <td><v-checkbox v-model="item.select" dense hide-details /></td>
                          <td>{{ item.text }}</td>
                          <td>{{ item.value }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-btn color="primary" class="float-right mt-2 ml-2" @click="createIndex">
                    {{ $t("Create index ") }}
                  </v-btn>
                  <v-btn color="primary" class="float-right mt-2" @click="saveIndex">{{ $t("Save index list") }}</v-btn>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined color="error" @click="show = false">
          {{ $t("Anulează") }}
        </v-btn>
        <v-btn color="primary" @click="saveData">
          {{ $t("Salvează") }}
        </v-btn>
      </v-card-actions>
      <v-overlay v-model="overlay" absolute>
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "../../plugins/axios"
import ScreamDrag from "../../components/containers/ScreamDrag"
import { EventBus } from "../../EventBus"
import draggable from "vuedraggable"
let idGlobal = 50
export default {
  components: {
    draggable,
    ScreamDrag
  },
  data: () => ({
    overlay: false,
    companiesValues: [],
    appTypesValues: [],
    companies: null,
    appTypes: null,
    recordId: "",
    record: {},
    etag: "",
    show: false,
    title: "",
    table: "",
    list: [],
    listFields: [],
    listJson: {},
    selectedTab: 0,
    selectedId: 0,
    boolValue: [
      {
        text: "None",
        value: ""
      },
      {
        text: "true",
        value: true
      },
      {
        text: "false",
        value: false
      }
    ],
    selectedItem: {},
    schema: {
      key: "",
      meta: { label: "", field: "" },
      type: "string|boolean|integer|float|number|datetime|list|dict",
      required: "true|false",
      readonly: "true|false",
      minlength: "0-9",
      maxlength: "0-9",
      min: "0",
      max: "9",
      empty: "true|false",
      unique: "true|false",
      nullable: "true|false",
      default: "",
      data_relation: {
        resource: "",
        field: "",
        embeddable: "true|false",
        version: "true|false"
      },
      regex: ""
    },
    listIndex: {
      dict: 0,
      list: 1,
      string: 2,
      integer: 3,
      float: 4,
      number: 5,
      boolean: 6,
      datetime: 7,
      objectid: 8,
      media: 9
    },
    mainList: [
      {
        id: 1,
        key: "",
        meta: { label: "" },
        type: "dict",
        icon: "mdi-code-braces",
        required: false,
        readonly: false,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: "",
        schema: []
      },
      {
        id: 2,
        key: "",
        meta: { label: "" },
        type: "list",
        icon: "mdi-code-brackets",
        required: false,
        readonly: false,
        minlength: null,
        maxlength: null,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: "",
        allowed: [""],
        schema: []
      },
      {
        id: 3,
        key: "",
        meta: { label: "" },
        type: "string",
        icon: "mdi-alpha-s-box",
        required: false,
        readonly: false,
        minlength: null,
        maxlength: null,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        data_relation: {
          resource: "",
          field: "",
          embeddable: false,
          version: false
        },
        regex: "",
        allowed: [""]
      },
      {
        id: 16,
        key: "",
        meta: { label: "" },
        type: "integer",
        icon: "mdi-alpha-i-box",
        required: false,
        readonly: false,
        min: null,
        max: null,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        data_relation: {
          resource: "",
          field: "",
          embeddable: false,
          version: false
        },
        regex: ""
      },
      {
        id: 17,
        key: "",
        meta: { label: "" },
        type: "float",
        icon: "mdi-alpha-f-box",
        required: false,
        readonly: false,
        min: null,
        max: null,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: ""
      },
      {
        id: 4,
        key: "",
        meta: { label: "" },
        type: "number",
        icon: "mdi-alpha-n-box",
        required: false,
        readonly: false,
        min: null,
        max: null,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        data_relation: {
          resource: "",
          field: "",
          embeddable: false,
          version: false
        },
        regex: ""
      },
      {
        id: 5,
        key: "",
        meta: { label: "" },
        type: "boolean",
        icon: "mdi-alpha-b-box",
        required: false,
        readonly: false,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: ""
      },
      {
        id: 6,
        key: "",
        meta: { label: "" },
        type: "datetime",
        icon: "mdi-alpha-d-box",
        required: false,
        readonly: false,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: ""
      },
      {
        id: 7,
        key: "",
        meta: { label: "", field: "" },
        type: "datarelation",
        icon: "mdi-transit-connection-variant",
        required: false,
        readonly: false,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        data_relation: {
          resource: "",
          field: "",
          embeddable: false,
          version: false
        },
        regex: ""
      },
      {
        id: 15,
        key: "",
        meta: { label: "" },
        type: "media",
        icon: "mdi-file-outline",
        required: false,
        readonly: false,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: ""
      },
      {
        id: 8,
        key: "",
        meta: { label: "" },
        type: "point",
        icon: "mdi-map-marker",
        required: false,
        readonly: false,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: ""
      },
      {
        id: 9,
        key: "",
        meta: { label: "" },
        type: "multipoint",
        icon: "mdi-map-marker-plus",
        required: false,
        readonly: false,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: ""
      },
      {
        id: 10,
        key: "",
        meta: { label: "" },
        type: "linestring",
        icon: "mdi-vector-line",
        required: false,
        readonly: false,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: ""
      },
      {
        id: 11,
        key: "",
        meta: { label: "" },
        type: "multilinestring",
        icon: "mdi-vector-polyline",
        required: false,
        readonly: false,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: ""
      },
      {
        id: 12,
        key: "",
        meta: { label: "" },
        type: "polygon",
        icon: "mdi-vector-rectangle",
        required: false,
        readonly: false,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: ""
      },
      {
        id: 13,
        key: "",
        meta: { label: "" },
        type: "multipolygon",
        icon: "mdi-vector-polygon",
        required: false,
        readonly: false,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: ""
      },
      {
        id: 14,
        key: "",
        meta: { label: "" },
        type: "geometrycollection",
        icon: "mdi-shape",
        required: false,
        readonly: false,
        empty: true,
        unique: false,
        nullable: false,
        show: false,
        default: "",
        regex: ""
      }
    ]
  }),
  computed: {
    inputRequired() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    },
    appType() {
      return this.$store.getters.appType
    },
    userappTypes() {
      return this.$store.getters.appTypes
    }
  },
  mounted() {
    let compurl = "app_company"
    if (this.appType !== "system") {
      this.companies = this.companyId
      compurl = 'app_company?where={"_id":"' + this.companyId + '"}'
    }

    axios.get('app_system_dictionary?where={"value":"app_type"}').then(response => {
      if (this.appType !== "system") {
        this.appTypesValues = response.data._items[0].content.filter(itm => this.userappTypes.includes(itm.value))
      } else {
        this.appTypesValues = response.data._items[0].content
      }
    })
    axios.get(compurl).then(response => {
      this.companiesValues = response.data._items
    })
  },
  methods: {
    createIndex() {
      this.$log("createIndex ", this.listFields)

      axios.get("db_setup_nonmarc_fulltext_indexes/?col_name=" + this.record.item_url).then(res => {
        this.$log("set index ", res)
        if (res.status === 200) {
          EventBus.$emit("show-alert", { message: this.$t("Index created"), timeOut: 7000 })
        }
      })
    },
    saveIndex() {
      this.$log("saveIndex ", this.listFields)
      const arr = []
      this.listFields.map(itm => {
        if (itm.select) {
          arr.push(itm.value)
        }
      })
      this.$log("saveIndex arr ", arr)
      axios
        .patch(
          "app_schema/" + this.recordId,
          { fulltext_index_fields: arr },
          {
            headers: {
              "If-Match": this.etag
            }
          }
        )
        .then(response => {
          this.$log("saveData index", response)
          if (response.status === 200) {
            this.etag = response.data._etag
            EventBus.$emit("show-alert", { message: this.$t("List index saved"), timeOut: 7000 })
            //   axios.get("db_setup_nonmarc_fulltext_indexes/?col_name=" + this.record.item_url).then(res => {
            //     this.$log("set index ", res)
            //   })
          }
        })
    },
    cloneComponent(prop) {
      const newprop = JSON.parse(JSON.stringify(prop))
      newprop.id = idGlobal++
      if (newprop.schema) newprop.schema = []
      return newprop
    },
    getJson() {
      this.listJson = {}
      this.listFields = []
      this.listJson = this.generateJson(this.listJson, this.list)
      this.generateListFields(this.listJson, "")
      this.listFields.map(itm => {
        if (this.record.fulltext_index_fields) {
          if (this.record.fulltext_index_fields.indexOf(itm.value) >= 0) {
            itm.select = true
          }
        }
      })
    },
    generateJson(json, list) {
      list.map(itm => {
        const dic = { ...itm }
        if (itm.type === "datarelation") dic.type = "objectid"
        dic.id = undefined
        dic.key = undefined
        dic.icon = undefined
        dic.show = undefined
        if (dic.meta.label === "" && !dic.meta.field) dic.meta = undefined
        if (dic.minlength == null) dic.minlength = undefined
        if (dic.maxlength == null) dic.maxlength = undefined
        if (dic.min == null) dic.min = undefined
        if (dic.max == null) dic.max = undefined
        if (dic.allowed != undefined) {
          if (dic.allowed.length == 1 && dic.allowed[0] == "") {
            dic.allowed = undefined
          }
        }
        if (dic.default === "") dic.default = undefined
        if (dic.regex === "") dic.regex = undefined
        if (!dic.required) dic.required = undefined
        if (!dic.readonly) dic.readonly = undefined
        if (dic.empty) dic.empty = undefined
        if (!dic.unique) dic.unique = undefined
        if (!dic.nullable) dic.nullable = undefined
        if (dic.data_relation) {
          if (!dic.data_relation.embeddable) dic.data_relation.embeddable = undefined
          if (!dic.data_relation.version) dic.data_relation.version = undefined
          if (dic.data_relation.resource === "") dic.data_relation.resource = undefined
          if (dic.data_relation.field === "") dic.data_relation.field = undefined
          let bool = true
          for (const prop in dic.data_relation) {
            if (dic.data_relation[prop]) bool = false
          }
          if (bool) {
            dic.data_relation = undefined
          }
        }
        if (itm.schema) {
          if (itm.schema.length > 0) {
            dic.schema = {}
            this.generateJson(dic.schema, itm.schema)
          } else {
            dic.schema = undefined
          }
        }
        if (itm.key) {
          json[itm.key] = { ...dic }
        } else {
          Object.assign(json, dic)
        }
      })
      return json
    },
    addData() {
      this.show = true
      this.overlay = true
      this.recordId = 0
      this.title = ""
      this.table = ""
      this.list = []
      this.selectedItem = {}
      this.appTypes = ""
      this.$nextTick(() => {
        this.$refs.dataForm.resetValidation()
        this.overlay = false
      })
    },
    editData(e) {
      this.show = true
      this.overlay = true
      this.recordId = e._id
      this.etag = e._etag
      // this.$log("etag ", this.etag)
      axios
        .get("app_schema/" + this.recordId)
        .then(response => {
          // this.$log("schema", response)
          response.data._created = undefined
          response.data._updated = undefined
          response.data._links = undefined
          response.data._version = undefined
          response.data._latest_version = undefined
          this.record = response.data
          this.title = response.data.title
          this.table = response.data.collection
          this.etag = response.data._etag
          if (this.record.owner) {
            this.companies = this.record.owner.company
            this.appTypes = this.record.owner.app_type
          } else {
            this.companies = null
            this.appTypes = null
          }
          this.list = []
          this.generateList(this.list, response.data.content)
          this.$nextTick(() => {
            this.overlay = false
          })
        })
        .catch(() => {
          this.$nextTick(() => {
            this.overlay = false
          })
        })
    },
    generateListFields(rec, name) {
      for (const prop in rec) {
        if (rec[prop]) {
          if ((prop === "type" || prop === "schema") && rec.type && rec.schema) {
            if (prop === "type") {
              this.generateListFields(rec.schema, name)
            }
          } else {
            let newname = ""
            if (name) {
              newname = name + "." + prop
            } else {
              newname = prop
            }
            let lbl = newname
            const arr = lbl.split(".")
            if (rec[prop].meta) {
              if (rec[prop].meta.label) {
                if (arr[0] == "fields" && arr[3] == "val") {
                  lbl = rec[prop].meta.label + "(" + arr[1] + (arr[2] ? arr[2] : "") + ")"
                } else {
                  lbl = rec[prop].meta.label
                }
              }
            }
            if (rec[prop].schema) {
              if (rec[prop].type === "list") {
                if (rec[prop].schema) {
                  if (rec[prop].schema.type != "dict") {
                    this.listFields.push({ text: lbl, value: newname })
                  }
                } else {
                  this.listFields.push({ text: lbl, value: newname })
                }
              }
              this.generateListFields(rec[prop].schema, newname)
            } else {
              if (
                rec[prop].type === "string" &&
                ((arr[0] == "fields" && arr[3] != "ord" && arr[2] != "ord") || arr[0] != "fields")
              ) {
                this.listFields.push({ text: lbl, value: newname })
              }
            }
          }
        }
      }
    },
    generateList(list, rec) {
      let bool = true
      if (rec.type) {
        if (typeof rec.type !== "object") {
          bool = false
          //const dic = { ...this.mainList[this.listIndex[rec.type]] }
          const dic = JSON.parse(JSON.stringify(this.mainList[this.listIndex[rec.type]]))
          if (rec.type === "objectid") rec.type = "datarelation"
          dic.id = idGlobal++
          Object.assign(dic, rec)
          if (rec.schema) {
            dic.schema = []
            this.generateList(dic.schema, rec.schema)
          }
          list.push(dic)
        }
      }
      if (bool) {
        for (const prop in rec) {
          //const dic = { ...this.mainList[this.listIndex[rec[prop].type]] }
          //this.$log("prop1 ", prop)
          //this.$log("prop2 ", rec[prop])
          //this.$log("prop3 ", rec[prop].type)
          //this.$log("prop4 ", this.listIndex[rec[prop].type])
          //this.$log("prop5 ", prop)
          const dic = JSON.parse(JSON.stringify(this.mainList[this.listIndex[rec[prop].type]]))
          if (rec[prop].type === "objectid") rec[prop].type = "datarelation"
          dic.key = prop
          dic.id = idGlobal++
          Object.assign(dic, rec[prop])
          if (rec[prop].schema) {
            dic.schema = []
            this.generateList(dic.schema, rec[prop].schema)
          }
          list.push(dic)
        }
      }
    },
    rebuildSchema() {
      // this.$log("rebuildSchema")
      // axios.get("rebuild_config").then(response => {
      //   this.$log("rebuild response ", response)
      // })
    },
    saveData() {
      if (this.$refs.dataForm.validate()) {
        this.getJson()
        // this.$log("this.listJson ", this.listJson)
        // this.$log("this.listJson ", JSON.stringify(this.listJson))
        let itemUrl = ""
        if (this.appTypes === "system") {
          itemUrl = "app_" + this.table
        } else if (this.companies) {
          itemUrl = this.appTypes + "_" + this.table + "_" + this.companyId
        } else {
          itemUrl = this.appTypes + "_" + this.table
        }
        if (this.recordId === 0) {
          axios
            .post("app_schema/", {
              title: this.title,
              item_url: itemUrl,
              item_title: itemUrl,
              collection: this.table,
              content: this.listJson,
              owner: {
                company: this.companies ? this.companies : undefined,
                app_type: this.appTypes
              }
            })
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 201) {
                this.show = false
                this.$emit("reload")
                this.rebuildSchema()
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        } else {
          // put
          this.record.title = this.title
          this.record.item_url = itemUrl
          this.record.item_title = itemUrl
          this.record.collection = this.table
          this.record.content = this.listJson
          this.record._etag = undefined
          this.record.owner = {
            company: this.companies ? this.companies : undefined,
            app_type: this.appTypes
          }
          // this.$log("this.record ", this.record)
          axios
            .put("app_schema/" + this.recordId, this.record, {
              headers: {
                "If-Match": this.etag
              }
            })
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 200) {
                this.show = false
                this.$emit("reload")
                this.rebuildSchema()
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        }
      }
    }
  }
}
</script>

<style lang="sass">
.tab-details
  .v-tabs-bar
    height: 36px
  .v-tab
    min-width: 50px
</style>
<style scoped>
.page-card {
  height: calc(100vh - 415px);
  overflow-y: auto;
  overflow-x: hidden;
}
.page-card-design {
  height: calc(100vh - 465px);
  overflow-y: auto;
  overflow-x: hidden;
}
ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
</style>
