<template>
  <draggable class="dragArea list-group" tag="ul" :list="schema" :group="{ name: 'g1' }" @change="dragChange">
    <li
      v-for="(el, index) in schema"
      :key="el.id"
      style="cursor:move;"
      class="list-group-item  pb-2 "
      :class="[horizontal ? floatLeft : '']"
    >
      <v-system-bar :class="[selectedId == el.id ? selectedElm : '']" @click="$emit('selectedIdChange', el)">
        <v-icon>{{ el.ico ? el.ico : el.icon }}</v-icon>
        {{ el.mandatory ? "*" : "" }}
        {{ el.key ? el.key + (el.meta ? " - " + el.meta.label : "") : el.type }}
        <v-spacer />
        <v-icon @click.stop="schema.splice(index, 1)">
          mdi-delete
        </v-icon>
      </v-system-bar>
      <scream-drag
        v-if="el.schema"
        :schema="el.schema"
        :selected-id="selectedId"
        :horizontal="el.horizontal"
        @selectedIdChange="$emit('selectedIdChange', $event)"
      />
    </li>
  </draggable>
</template>
<script>
import draggable from "vuedraggable"
export default {
  name: "ScreamDrag",
  components: {
    draggable
  },
  props: {
    selectedId: {
      type: Number,
      default: 0
    },
    schema: {
      required: true,
      type: Array
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    floatLeft: "floatLeft",
    selectedElm: "selectedElm",
    selectedElelemnt: {}
  }),
  methods: {
    dragChange(e) {
      if (e.added) this.$emit("selectedIdChange", e.added.element)
      if (e.moved) this.$emit("selectedIdChange", e.moved.element)
    }
  }
}
</script>
<style scoped>
.dragArea {
  min-height: 20px;
  /* outline: 1px dashed; */
}
ul {
  list-style-type: none;
}
.selectedElm {
  border: 1px solid #888;
}
.floatLeft {
  display: inline-flex;
}
</style>
